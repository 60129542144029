import RotorLogoSVG from '@/svg/rotor-logo.svg';
import JewerlyLogoSVG from '@/svg/jewerly-logo.svg';
import { useCursorTypeState } from '@/atoms/cursor';
import { useIsAppNavOpenedValue } from '@/atoms/opened-popups';
import classNames from 'classnames';
import Link from '@/components/shared/Link';
import Nav from '../Nav';

const Header = () => {
    const [, setCursorType] = useCursorTypeState();
    const isAppNavPopupOpened = useIsAppNavOpenedValue();

    return (
        <header
            className={classNames('header wrapper', {
                'burger-opened': isAppNavPopupOpened,
            })}
        >
            <div className="header-inner">
                <div className="header-logos-wrapper">
                    <div className="header-logos">
                        <Link
                            href="/rotormine"
                            className="header-logo header-logo-rotor"
                            onPointerOver={() => {
                                setCursorType('logo-rotor');
                            }}
                            onPointerOut={() => setCursorType('default')}
                            aria-label="Rotormine"
                        >
                            <RotorLogoSVG />
                        </Link>
                        <Link
                            href="/jewelrymine"
                            className="header-logo header-logo-jewerly"
                            onPointerOver={() => {
                                setCursorType('logo-jewerly');
                            }}
                            onPointerOut={() => setCursorType('default')}
                            aria-label="Jewelrymine"
                        >
                            <JewerlyLogoSVG />
                        </Link>
                    </div>
                </div>
                <Nav />
            </div>
        </header>
    );
};

export default Header;
